footer {
  width: 100%;
  border-top: solid $c2 2px;
  border-bottom: solid $c2 2px;
  margin: 4px 0;
  display: none;
  &.load {
    display: block;
  }
  &.loading {
    display: none;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
  }
}
