@import "@fontsource/quicksand";
@import "@fontsource/mulish";
@import "@fontsource/ibm-plex-sans";
@import "@fontsource/inter";

$c1: white;
$c2: rgba(0, 0, 0, 0.34);
$c3: #0b4aae;
$c4: transparentize(#0b4aae, 0.9);
$c5: gold;
$c6: black;

$font2: "Quicksand";
$font1: "IBM Plex Sans";
$font3: "Mulish";
$font4: "Inter";
