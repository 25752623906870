.mapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 80px 0;
  .grid {
    &:nth-child(1) {
      width: 36%;
      padding: 0 4% 0 4%;
      @include max_sm {
        width: 100%;
        padding: 0;
      }
      h2 {
        font-family: $font2;
        color: $c3;
        margin: 0 0 0 10px;
        font-size: inherit;
        font-weight: 500;
        @include max_sm {
          margin: 0 0 8px 10px;
        }
        svg {
          vertical-align: sub;
          float: right;
          margin: 0 25px 0 0;
        }
      }
      p {
        color: $c3;
        background-color: $c4;
        border: 2px solid $c2;
        border-radius: 2.5px;
        font-family: $font3;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px;
        @include max_xl {
          border-radius: 0;
        }
        @include max_sm {
          width: calc(92% - 24px);
          margin: auto;
        }
        span {
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
        }
      }
    }
    &:nth-child(2) {
      width: 52%;
      padding: 0 4% 0 0;
      @include max_sm {
        width: 92%;
        padding: 0;
      }
      div {
        border-radius: 10px;
        @include max_sm {
          border-radius: 0;
        }
      }
    }
  }
}

.aboutContainer {
  margin: 20px auto auto auto;
  width: 100%;
  max-width: 1200px;
  h2 {
    font-family: $font2;
    font-size: inherit;
    color: $c3;
    margin: 0 0 0 10px;
    width: calc(100% - 10px);
    font-weight: 500;
    svg {
      float: right;
      margin: 0 25px 0 0;
    }
  }
  h3 {
    font-family: $font2;
    text-align: right;
    font-size: 90%;
    font-weight: 500;
    margin: 0;
    color: $c3;
  }
  #spanOne {
    width: 100%;
    margin: 25px auto auto auto;
    display: block;
    text-align: right;
  }
  span {
    display: inline-block;
  }
  #divider {
    margin: 6% 0 0 0;
  }
  #imageContainer {
    position: relative;
    clip-path: inset(0 0.5px 0 round 26px 0);
    float: left;
    width: 55%;
    height: 379px;
    background-color: $c3;
    @include max_xxl {
      float: none;
      margin: 25px auto 45px auto;
      width: 100%;
      height: 66vw;
      max-width: 625px;
      max-height: 415px;
    }
    img {
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      object-position: center;
      object-fit: cover;
      filter: grayscale(100%);
      clip-path: inset(0 0.5px 0 round 25px 0);
    }
  }
  #spanTwo {
    width: 37.5%;
    padding:  0 0 0 7.5%;
    margin: 25px 0 0 0;
    text-align: right;
    height: 268px;
    @include max_xxl {
      padding: 0;
      width: 100%;
      margin: auto;
      height: initial;
    }
  }
  button {
    background-color: transparent;
    outline: none;
    padding: 20px;
    font-size: 80%;
    color: $c3;
    border: 1px solid $c3;
    border-radius: 100px;
    display: block;
    margin: 25px 2.5% 25px auto;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      background-color: $c3;
      color: $c1;
    }
    @include max_xxl {
      margin: 25px auto 25px 2.5%;
    }
  }
  #moreOne {
    hr {
      margin: 8px 0 23px 0;
    }
  }
  #moreOne,
  #moreTwo {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s;
    text-align: justify;
    font-size: medium;
  }
}
