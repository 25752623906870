.svg {
  color: $c3;
  border: 2px solid $c3;
  max-width: 1200px;
  margin: auto auto 80px auto;
  contain: paint;
  @include max_xxl {
    width: 92%;
    margin: 4% auto 4% auto;
  }
  .sticky {
    height: 200px;
    background-image: url("https://candid.s3-ap-southeast-2.amazonaws.com/stickyTop.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    width: 100%;
  }
  .prop {
    font-family: $font3;
    padding: 0 7% 50px 7%;
    font-weight: 500;
    position: relative;
    z-index: 999;
  }
  .wave {
    opacity: 0.2;
    height: 130px;
    background-image: url("https://candid.s3-ap-southeast-2.amazonaws.com/wave.svg");
    animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  }
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
}
