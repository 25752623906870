.blogHeading {
  width: 100%;
  max-width: 1204px;
  margin: 80px auto 0 auto;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  background-color: $c3;
  @include max_xxl {
    width: 92%;
  }
  @include max_xl {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: auto;
    width: 100%;
    background-color: $c4;
    h1 {
      color: $c3;
    }
  }
}

.blogContainer {
  width: 100%;
  border-left: 2px solid $c3;
  border-right: 2px solid $c3;
  border-bottom: 2px solid $c3;
  max-width: 1200px;
  width: calc(100% - 4px);
  margin: 0 auto 80px auto;
  @include max_xxl {
    width: calc(92% - 4px);
  }
  @include max_xl {
    margin: auto;
    width: 100%;
    border: none;
  }
  .blogResponse {
    overflow-y: auto;
    scrollbar-color: $c3 $c1;
    color: $c6;
    position: relative;
    overscroll-behavior: none;
    min-height: calc(100vh - 160px);
    @include max_xl {
      min-height: 100vh;
    }
    .sticky {
      height: 200px;
      opacity: 0.2;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 200px;
        background-image: url("https://candid.s3-ap-southeast-2.amazonaws.com/stickyTop.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background: $c1;
    }
    &::-webkit-scrollbar-thumb {
      background: $c3;
    }
    table {
      padding: 0 20px;
      position: relative;
      z-index: 4;
      width: 100%;
      margin: 0 0 50px 0;
      td {
        font-size: inherit;
        width: 100%;
      }
      tr {
        display: flex;
        margin: 10px auto;
      }
      img {
        margin: auto 0;
        display: inline-block;
        width: 50%;
        max-width: 150px;
      }
      hr {
        width: 60%;
        margin: 25px auto;
      }
      .center {
        text-align: center;
        padding: 50px 0 0 0;
      }
    }
  }
  .accordianHeading {
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    width: 100%;
    h2 {
      line-height: 40px;
      font-family: $font1;
      color: $c1;
      float: right;
      font-size: inherit;
      margin: 0;
      padding: 0 20px 0 0;
      font-weight: 500;
    }
  }
  .MuiAccordionSummary-expandIconWrapper svg {
    fill: $c1;
  }
  .MuiButtonBase-root {
    background-color: $c3;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiPaper-root.MuiAccordion-root {
    margin: 0;
  }
  form {
    font-family: $font3;
    color: $c6;
    width: 100%;
    input::-webkit-input-placeholder,
    input::placeholder,
    textarea::-webkit-input-placeholder,
    textarea::placeholder {
      color: $c6;
      opacity: 1;
    }
    .MuiFormControl-root {
      display: flex;
      align-items: flex-end;
      margin: auto 0 2.5px auto;
      @include max_sm {
        width: calc(100% - 50px);
        margin: 25px 25px 0 25px;
        .MuiInputBase-root {
          width: 100%;
        }
      }
    }
    .MuiFormControl-fullWidth {
      margin: 25px 0 0 0;
      width: 100%;
      @include max_sm {
        width: calc(100% - 50px);
        margin: 25px 25px 0 25px;
      }
    }
    .MuiInputBase-multiline {
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: $c3 transparent;
      &::-webkit-scrollbar {
        width: 15px;
      }
      &::-webkit-scrollbar-thumb {
        background: $c3;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
  .formAdd {
    width: 100%;
    label[for="avatar"] {
      display: block;
      width: fit-content;
      margin: 25px auto;
    }
    input[type="file"] {
      border: 1px solid $c2;
      border-radius: 3px;
      margin: auto;
      padding: 4px 8px;
      color: $c3;
      background-color: $c1;
      display: block;
      width: 75%;
      max-width: 300px;
      cursor: pointer;
      &::file-selector-button {
        background-color: $c1;
        border: 1px solid $c3;
        color: $c3;
        padding: 8px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
  .formRemove {
    width: 100%;
    margin: auto;
    padding: 25px 0 0 0;
    .MuiFormControl-root {
      width: calc(100% - 50px);
      margin: 0 25px 25px 25px;
      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
}
