.galleryHeading {
  max-width: 1204px;
  margin: 80px auto auto auto;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  background-color: $c3;
  width: 100%;
  @include max_xxl {
    width: 92%;
  }
  @include max_xl {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: auto;
    width: 100%;
    background-color: $c4;
    h1 {
      color: $c3;
    }
  }
}

#galleryContainer {
  border-left: 2px solid $c3;
  border-right: 2px solid $c3;
  border-bottom: 2px solid $c3;
  display: flex;
  flex-direction: column;
  width: calc(100% - 4px);
  max-width: 1200px;
  margin: 0 auto 80px auto;
  position: relative;
  min-height: calc(100vh - 90px);
  @include max_xxl {
    width: calc(92% - 4px);
  }
  @include max_xl {
    min-height: 100vh;
    margin: 0;
    width: 100%;
    border: none;
  }
  .sticky {
    height: 200px;
    opacity: 0.2;
    @include max_sm {
      height: 150px;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 200px;
      background-image: url("https://candid.s3-ap-southeast-2.amazonaws.com/stickyTop.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  .slickGallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .imggalleryContainer {
      padding: 39vh 0;
      position: relative;
      height: 100%;
      @include max_sm {
        padding: 33vh 0;
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
      border: 2px solid $c2;
      box-sizing: border-box;
      position: absolute;
      object-fit: contain;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .slick-next,
    .slick-prev {
      position: static;
      &::before {
        color: $c3;
      }
    }
    .slick-dots {
      position: static;
      width: 100%;
      margin: 0 0 65px 0;
    }
    .slick-list {
      width: calc(80% - 40px);
      margin: 0 5%;
    }
  }
}
