header {
  width: 100%;
  height: 65px;
  background-image: linear-gradient(-90deg, $c1 0%, $c4 75%);
  background-color: $c1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 997;
  padding: 5px 0;
  transition: padding 0.4s ease;
  will-change: padding;
  &.hasAnimation {
    padding: 0;
    a {
      transform: scale(0.95);
    }
  }
  a {
    display: block;
    float: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $c6;
    margin: 0 0 0 15px;
    font-weight: 500;
    font-size: clamp(14px, 4vw, 23px);
    display: flex;
    transition: transform 0.4s ease;
    transform: scale(1);
    will-change: transform;
    @include min_hd {
      margin: 0 0 0 calc(50vw - 960px);
    }
    img {
      display: flex;
      width: 50px;
      margin: 0 0 0 3px;
    }
  }
}

.burger {
  width: 75px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .clickBurger {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
  &:hover {
    cursor: pointer;
  }
}

.bar {
  background-color: $c3;
}

nav {
  position: fixed;
  width: 200px;
  background-color: $c1;
  background-image: linear-gradient(0deg, $c4 60%, $c1 100%);
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  z-index: 996;
  right: 0;
  text-align: center;
  transform: translate(100%);
  font-family: $font4;
  padding: 100px 0 0 0;
  transition: transform 0.6s;
  height: 100ch;
  &.true {
    transform: translate(0);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: $c3;
    font-size: 17px;
    a {
      height: 25px;
      width: 100%;
      cursor: pointer;
      border: none;
      display: block;
      text-decoration: none;
      outline: none;
      padding: 10px 0 0 0;
      color: inherit;
      transition: 0.2s ease-in-out color;
      &:hover {
        color: $c5;
      }
    }
    #svg {
      transition: 0.2s ease-in-out fill;
      &:hover {
        fill: $c5;
      }
    }
  }
}
