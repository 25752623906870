.detailsContainer {
  margin: 80px auto;
  max-width: 1200px;
  width: 100%;
  .contactOne {
    float: left;
    object-position: center;
    object-fit: cover;
    width: 25%;
    margin: 0 0 0 4%;
    filter: grayscale(50%);
    clip-path: circle(40%);
    @include max_sm {
      float: none;
      display: block;
      margin: 10% auto;
      min-width: 200px;
    }
  }
  .contactTwo {
    float: right;
    margin: 0 8% 0 0;
    @include max_sm {
      float: none;
      margin: auto;
      text-align: left;
      word-wrap: break-word;
    }
    h2 {
      width: calc(100% - 10px);
      font-family: $font2;
      color: $c3;
      margin: 0 0 0 10px;
      font-size: inherit;
      font-weight: 500;
      svg {
        margin: 0 25px 0 0;
        float: right;
        font-size: 24px;
      }
    }
    p {
      color: $c3;
      background-color: $c4;
      border: 2px solid $c2;
      border-radius: 2.5px;
      padding: 10px;
      font-family: $font3;
      min-width: 355px;
      margin: 0.83em 0 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      @include max_sm {
        border-radius: 0;
        width: calc(92% - 24px);
        margin: 1em auto auto auto;
        min-width: initial;
      }
      .hours {
        height: 35px;
      }
      .enquiries {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        svg {
          padding: 0 0 0 5px;
          font-size: 25px;
          vertical-align: bottom;
          cursor: pointer;
          transition: 0.2s ease-in-out fill;
          &:hover {
            fill: $c5;
          }
        }
      }
      a {
        color: $c3;
        text-decoration: none;
        height: 55px;
        display: flex;
        align-items: center;
        transition: 0.2s ease-in-out color;
        &:hover {
          color: $c5;
        }
      }
    }
  }
}

.contactContainer {
  max-width: 1200px;
  margin: 20px auto auto auto;
  h2 {
    width: calc(100% - 10px);
    font-family: $font2;
    color: $c3;
    font-size: inherit;
    font-weight: 500;
    margin: 0 0 0 10px;
    svg {
      margin: 0 25px 0 0;
      float: right;
      font-size: 24px;
    }
  }
  #contactResponse {
    text-align: center;
    display: inline-block;
  }
}
